import Vue from 'vue';
import App from './App';

import router from '@/app/router';
import VuexRouterSync from 'vuex-router-sync';

import store from '@/app/store/';

import { browserCheck } from '@/app/mixins';

browserCheck.created();

VuexRouterSync.sync( store, router );

Vue.config.productionTip = false;

/* init box2d web assembly */
let box2DSource = '/static/scripts/Box2D_v2.3.1.min.wasm.js';

if( browserCheck.browserCheck.isIE || browserCheck.browserCheck.isSafari )
{
    box2DSource = '/static/scripts/Box2D_v2.3.1_min.js';
}

let scriptTag = document.createElement( 'script' );
scriptTag.src = box2DSource;
let elem = document.querySelector( 'body' ).appendChild( scriptTag );

elem.addEventListener( 'load', () =>
{
    store.dispatch( 'Box2DLoaded' );
});
/* end of wasm init */

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    store,
    components: { App },
    template: '<App/>'
});
