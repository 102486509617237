<template>
    <div cass="game-wrapper">
        <canvas class="debug" ref="debugCanvas" :width="innerW" :height="innerH"></canvas>
        <canvas class="pixi" ref="pixiCanvas" :width="innerH * 0.6" :height="innerH"></canvas>

        <div class="paneltje">
            <h2 ref="multiplier">multiplier {{ Math.round( scoreMultiplier * 100 ) / 100 }}x</h2>

            <p>Highscore: {{ highestScore }}</p>
            <ul>
                <li>Deze ronde: {{ Math.round( score ) }}</li>
                <li>Vorige ronde: {{ Math.round( prevScore ) }}</li>
            </ul>
        </div>
        <!-- <div class="paneltje">
            <p>left/right arrow = flippers</p>
            <p>space = plunger</p>
        </div> -->
    </div>
</template>

<style lang="scss" scoped>
    .game-wrapper{
        position:relative;
    }
    .paneltje{
        position:absolute;
        top:10%;
        left:40%;

        text-align: left;
        h2{
            display:inline-block;
        }
        p{
            padding-left:2.5em;
        }

        ul{
            list-style-type: none;
        }
    }
    .debug{
        position:relative;
        z-index:2;
    }
    .pixi{
        position:absolute;
        top:0;right:0;
    }
</style>

<script>
    import Pinball from '@/app/game/Main';
    import { browserCheck, eventify } from '@/app/mixins';

    export default {
        mixins: [ browserCheck, eventify ],
        data()
        {
            return {
                score: 0,
                prevScore: 0,
                highestScore: 0,
                scoreMultiplier: 1,

                innerW: window.innerWidth,
                innerH: window.innerHeight,

                leftFlipperKeycode: 37, // left arrow
                rightFlipperKeycode: 39, // right arrow
                plungerKeycode: 32, // space
            };
        },

        watch: {
            score: function( pVal )
            {
                this.scoreMultiplier = 1 + ( pVal / 100 );

                this.multiplierTl.restart();
            }
        },

        mounted()
        {
            this.currentInputs = {};

            this.onResizeRef = this.onResize;
            window.addEventListener( 'resize', this.onResizeRef );
            this.onResize();

            this.onKeyDownRef = this.onKeyDown;
            document.addEventListener( 'keydown', this.onKeyDownRef );

            this.onKeyUpRef = this.onKeyUp;
            document.addEventListener( 'keyup', this.onKeyUpRef );

            this.touchHandlerRef = this.touchHandler;
            document.addEventListener( 'touchstart', this.touchHandlerRef );
            document.addEventListener( 'touchend', this.touchHandlerRef );

            this.emitter = {};
            this.eventify( this.emitter );

            this.emitter.on( 'onScore', this.addScore );
            this.emitter.on( 'onDeath', this.cycleScore );

            this.app = new Pinball({ pixiCanvas: this.$refs.pixiCanvas, debugCanvas: this.$refs.debugCanvas, browserCheck: this.browserCheck, emitter: this.emitter });

            this.multiplierTl = new TimelineMax();
            this.multiplierTl.to( this.$refs.multiplier, 0.05, { scale: 1.1, yoyo: true, repeat: 1 }, 0 );

            // this.startScoreMultiplierTicker();
        },

        beforeDestroy()
        {
            this.app.destroy();
            this.app = null;

            window.removeEventListener( 'resize', this.onResizeRef );
            document.removeEventListener( 'keydown', this.onKeyDownRef );
            document.removeEventListener( 'keyup', this.onKeyUpRef );
        },

        methods: {
            startScoreMultiplierTicker()
            {
                if( this.multiplierInterval )
                {
                    clearInterval( this.multiplierInterval );
                }

                this.scoreMultiplier = 1;
                this.multiplierInterval = setInterval( () =>
                {
                    this.scoreMultiplier += 0.5;
                }, 10000 );
            },

            addScore( pScore )
            {
                this.score = Math.round( this.score + ( pScore * this.scoreMultiplier ) );

                if( this.score > this.highestScore )
                {
                    this.highestScore = this.score;
                }
            },

            cycleScore()
            {
                // this.startScoreMultiplierTicker();

                this.prevScore = this.score;
                if( this.prevScore > this.highestScore )
                {
                    this.highestScore = this.prevScore;
                }

                this.score = 0;
            },

            onResize()
            {
                this.innerW = this.$el.getBoundingClientRect().width;
                this.innerH = window.innerHeight;
            },

            onKeyDown( e )
            {
                if( this.currentInputs[ e.keyCode ] )
                {
                    return;
                }

                this.currentInputs[ e.keyCode ] = 1;

                if( e.keyCode === this.leftFlipperKeycode )
                {
                    this.app.handleInput({ which: 'leftFlipper', what: 1 });
                }

                if( e.keyCode === this.rightFlipperKeycode )
                {
                    this.app.handleInput({ which: 'rightFlipper', what: 1 });
                }

                if( e.keyCode === this.plungerKeycode )
                {
                    this.app.handleInput({ which: 'plunger', what: 1 });
                }
            },

            onKeyUp( e )
            {
                if( this.currentInputs[ e.keyCode ] )
                {
                    delete this.currentInputs[ e.keyCode ];
                }

                if( e.keyCode === this.leftFlipperKeycode )
                {
                    this.app.handleInput({ which: 'leftFlipper', what: 0 });
                }

                if( e.keyCode === this.rightFlipperKeycode )
                {
                    this.app.handleInput({ which: 'rightFlipper', what: 0 });
                }

                if( e.keyCode === this.plungerKeycode )
                {
                    this.app.handleInput({ which: 'plunger', what: 0 });
                }
            },

            touchHandler( e )
            {
                if( e.changedTouches[0].pageX < window.innerWidth / 2 )
                {
                    this.app.handleInput({ which: 'leftFlipper', what: e.type === 'touchstart' ? 1 : 0 });
                }
                else if( e.changedTouches[0].pageX > window.innerWidth / 2 )
                {
                    this.app.handleInput({ which: 'rightFlipper', what: e.type === 'touchstart' ? 1 : 0 });
                }
            }
        }
    };
</script>
