/* eslint-disable */

export default class Box
{
    constructor( settings, pWorld, Box2D )
    {
        this.world = pWorld;

        this.shapeDef = new Box2D.b2PolygonShape();

        let buffer = Box2D._malloc( settings.vertices.length * 8 );
        let offset = 0;

        for( let i = 0; i < settings.vertices.length; i++ )
        {
            Box2D.HEAPF32[ buffer + offset >> 2 ] = settings.vertices[i].get_x();
            Box2D.HEAPF32[ buffer + (offset + 4) >> 2 ] = settings.vertices[i].get_y();
            offset += 8;
        }

        let ptr_wrapped = Box2D.wrapPointer( buffer, Box2D.b2Vec2 );
        this.shapeDef.Set( ptr_wrapped, settings.vertices.length );

        if( settings.debug )
        {
            console.log( 'shapeDef', this.shapeDef );
        }

        this.fixtureDef = new Box2D.b2FixtureDef();
        this.fixtureDef.set_density( settings.density ? settings.density : 0 );
        this.fixtureDef.set_shape( this.shapeDef );
        this.fixtureDef.set_friction( settings.friction ? settings.friction : 0 );

        if( settings.debug )
        {
            console.log( 'fixtureDef', this.fixtureDef.get_filter() );
        }

        this.bodyDef = new Box2D.b2BodyDef();
        this.bodyDef.set_position( new Box2D.b2Vec2( settings.x, settings.y ) );

        this.body = pWorld.CreateBody( this.bodyDef );
        this.body.CreateFixture( this.fixtureDef );
        this.body.name = settings.name ? settings.name : '';
        this.body.collisionId = settings.collisionId ? settings.collisionId : '';

        this.body.SetUserData( this );

        // if( settings.debug )
        // {
        //     this.body.SetFixedRotation( false );
        //     console.log( 'this.body', this.body );
        //     console.log( 'this.body', this.body.GetPosition() );
        // }
    }
};
