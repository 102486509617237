import createDebugDraw from './createDebugDraw';
import { TimelineMax, TweenMax, TweenLite } from 'gsap';

import * as PIXI from 'pixi.js';
window.PIXI = PIXI;

const pixiLayers = require( 'pixi-layers' );
const pixiLights = require( 'pixi-lights' );

PIXI.layers = pixiLayers;
PIXI.lights = pixiLights;

export {
    createDebugDraw,
    TimelineMax,
    TweenMax
};
