export default class InputHandler
{
    constructor( pWorld )
    {
        this.world = pWorld.world;
    }

    sortInput( pInput )
    {
        switch( pInput.which )
        {
            case 'leftFlipper':
            case 'rightFlipper':
                this.handleFlippers( pInput );
                break;
            case 'plunger':
                this.handlePlunger( pInput );
            default:
                break;
        }
    }

    handleFlippers( pInput )
    {
        if( pInput.which === 'leftFlipper' )
        {
            if( pInput.what === 1 )
            {
                this.world.leftFlipper.engage();
                this.world.leftFlipper2.engage();
            }
            else
            {
                this.world.leftFlipper.disengage();
                this.world.leftFlipper2.disengage();
            }
        }
        else
        {
            if( pInput.what === 1 )
            {
                this.world.rightFlipper.engage();
                this.world.rightFlipper2.engage();
            }
            else
            {
                this.world.rightFlipper.disengage();
                this.world.rightFlipper2.disengage();
            }
        }
    }

    handlePlunger( pInput )
    {
        if( pInput.what === 1 )
        {
            this.world.plunger.engage();
            this.world.plunger2.engage();
        }
        else
        {
            this.world.plunger.disengage();
            this.world.plunger2.disengage();
        }
    }
}
