
export default class Sensor{
    constructor( settings, pWorld, Box2D )
    {
        this.settings = settings;
        this.world = pWorld;

        this.shapeDef = new Box2D.b2PolygonShape();
        this.shapeDef.SetAsBox( settings.width / 2, settings.height / 2 );

        // this.shapeDef.set_m_count( 4 );

        if( settings.debug )
        {
            console.log( 'shape', this.shapeDef );
        }

        this.fixtureDef = new Box2D.b2FixtureDef();
        this.fixtureDef.set_shape( this.shapeDef );
        // this.fixtureDef.set_density( 0 );
        this.fixtureDef.set_isSensor( true );

        if( settings.debug )
        {
            console.log( 'fixture', this.fixtureDef );
        }

        this.bodyDef = new Box2D.b2BodyDef();
        this.bodyDef.set_position( new Box2D.b2Vec2( settings.x + settings.width / 2, settings.y + settings.height / 2 ) );
        this.bodyDef.set_angle( settings.angle ? settings.angle : 0 );
        // this.bodyDef.set_active( true );

        if( settings.debug )
        {
            console.log( 'bodydef', this.bodyDef );
        }

        this.body = pWorld.CreateBody( this.bodyDef );
        this.body.CreateFixture( this.fixtureDef );
        this.body.name = settings.name ? settings.name : 'Sensor';

        this.body.collisionId = settings.collisionId ? settings.collisionId : 0;
        this.body.SetUserData( this );

        this.body.onHit = settings.onHit;
    }
};
