
export default class Engine
{
    constructor( settings )
    {
        this.world = settings.world.world;
        this.renderer = settings.renderer;
        this.pixiRenderer = settings.pixiRenderer;
    }

    start()
    {
        this.loop();
    }

    stop()
    {
        cancelAnimationFrame( this.rafRef );
    }

    loop()
    {
        // this.renderer.checkResize();

        this.world.Step( 1 / 52, 3, 2 ); // 1 / 52

        if( this.world.ball )
        {
            this.world.ball.update();
        }

        if( this.world.ball2 )
        {
            this.world.ball2.update();
        }

        this.pixiRenderer.update();

        this.rafRef = requestAnimationFrame( this.loop.bind( this ) );
    }
}
