import Vue from 'vue';
import Vuex from 'vuex';

import game from './modules/game';

Vue.use( Vuex );
require( 'es6-promise' ).polyfill();

export default new Vuex.Store({
    strict: true,

    modules: {
        game
    },

    state: {
        Box2DReady: false
    },

    getters: {
        Box2DReady: state => state.Box2DReady
    },

    mutations: {
        assignBox2D: ( state, payload ) =>
        {
            state.Box2DReady = payload;
        }
    },

    actions: {
        Box2DLoaded: ( context ) =>
        {
            context.commit( 'assignBox2D', true );
        }
    }
});
