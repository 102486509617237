<template>
    <div id="app">
        <router-view :key="$route.path"></router-view>
    </div>
</template>

<style lang="scss">
    @import './static/scss/app.scss';
</style>

<script>
    export default {
        name: 'App'
    };
</script>
