<template>
    <div>
        <v-game v-if="box2DisLoaded"></v-game>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import Game from '@/app/components/Game';

    export default {
        components: {
            'v-game': Game
        },

        data()
        {
            return {
                appType: 'clean'
            };
        },

        computed: {
            box2DisLoaded()
            {
                return this.$store.getters['Box2DReady'];
            }
        }
    };
</script>
