/* eslint-disable */

export default class Box
{
    constructor( settings, pWorld, Box2D )
    {
        this.world = pWorld;
        // { x: 0, y: -20, width: 100, height: 1 }, this.world
        this.shapeDef = new Box2D.b2PolygonShape();
        this.shapeDef.SetAsBox( settings.width / 2, settings.height / 2 );
        if( settings.debug )
        {
            console.log( 'shapeDef', this.shapeDef );
        }

        this.fixtureDef = new Box2D.b2FixtureDef();
        this.fixtureDef.set_density( settings.density ? settings.density : 0 );
        this.fixtureDef.set_shape( this.shapeDef );

        if( settings.friction )
        {
            this.fixtureDef.set_friction( settings.friction );
        }

        // dit is volgensmij voor verschillende collision sets ofzo
        this.fixtureDef.get_filter().set_categoryBits( settings.categoryBits ? settings.categoryBits : 0x0001 );
        this.fixtureDef.get_filter().set_maskBits( settings.maskBits ? settings.maskBits : 0x0006 );

        if( settings.filter )
        {
            // this.fixtureDef.get_filter().set_maskBits( settings.filter.maskBits );
        }
        if( settings.debug )
        {
            console.log( 'fixtureDef', this.fixtureDef.get_filter() );
        }

        this.bodyDef = new Box2D.b2BodyDef();
        this.bodyDef.set_position( new Box2D.b2Vec2( settings.x + settings.width / 2, settings.y + settings.height / 2 ) );

        this.bodyDef.set_type( settings.type ? settings.type : Box2D.b2_staticBody );
        this.bodyDef.set_angle( settings.angle ? settings.angle : 0 );

        if( settings.debug )
        {
            console.log( 'bodyDef', this.bodyDef );
        }

        this.body = pWorld.CreateBody( this.bodyDef );
        this.body.CreateFixture( this.fixtureDef );
        this.body.name = settings.name ? settings.name : 'Box';
        this.body.collisionId = settings.collisionId ? settings.collisionId : '';

        this.body.SetUserData( this );

        if( settings.debug )
        {
            this.body.SetFixedRotation( false );
            console.log( 'this.body', this.body );
            console.log( 'this.body', this.body.GetPosition() );
        }

        if( this.body.collisionId === 'lowerBumper' )
        {
            this.body.score = 10;
        }
    }

    update()
    {
        let pos = this.body.GetPosition();
    }
}
