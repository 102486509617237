import Config from '@/app/game/data/config';

import Box from '@/app/game/objects/Box';
import Ball from '@/app/game/objects/Ball';
import Poly from '@/app/game/objects/Poly';
import Flipper from '@/app/game/objects/Flipper';
import Sensor from '@/app/game/objects/Sensor';
import Plunger from '@/app/game/objects/Plunger';

export default class World
{
    constructor( pGravity )
    {
        this.canvas = document.querySelector( 'canvas.debug' );
        this.rect = this.canvas.getBoundingClientRect();

        this.world = new Box2D.b2World( pGravity, true );

        this.world.groundBody = this.world.CreateBody( new Box2D.b2BodyDef() );

        this.wallSize = 0.1;
        this.ballSize = 0.3;
        this.bakWidth = 10;
        this.bakHeight = 20;

        this.constructLevel();
        this.constructNewLevel();
        this.contructInteractiveElements();
        this.constructSensors();

        this.elementsInNeedOfRedraw = [];
    }

    constructLevel()
    {
        this.world.wallTop = new Box({ x: 0, y: 0, width: this.bakWidth, height: this.wallSize, debug: false }, this.world, Box2D );
        this.world.wallLeft = new Box({ x: 0, y: 0, width: this.wallSize, height: this.bakHeight, debug: false }, this.world, Box2D );
        this.world.wallRight = new Box({ x: this.bakWidth, y: 0, width: this.wallSize * 2, height: this.bakHeight, debug: false }, this.world, Box2D );
        this.world.wallBottom = new Box({ x: 0, y: this.bakHeight, width: this.bakWidth, height: this.wallSize, debug: false }, this.world, Box2D );

        this.world.wallSlant = new Box({ x: this.bakWidth - 2, y: 1, width: 3, height: this.wallSize, angle: Math.PI/2.5 }, this.world, Box2D );

        this.ballGuide = new Box({ x: this.bakWidth - this.ballSize * 3, y: this.bakHeight * 0.25, width: this.wallSize / 2, height: this.bakHeight * 0.75 }, this.world, Box2D );

        this.world.channelWallLeft1 = new Box({ x: this.bakWidth * 0.13, y: this.bakHeight * 0.7, width: this.wallSize * 4, height: 2.3 }, this.world, Box2D );
        this.world.channelWallLeft2 = new Box({ x: this.bakWidth * 0.19, y: this.bakHeight * 0.8, width: this.wallSize * 4, height: 2, angle: -Math.PI / 4 }, this.world, Box2D );

        this.world.channelWallRight1 = new Box({ x: this.bakWidth * 0.75, y: this.bakHeight * 0.7, width: this.wallSize * 4, height: 2.3 }, this.world, Box2D );
        this.world.channelWallRight2 = new Box({ x: this.bakWidth * 0.69, y: this.bakHeight * 0.8, width: this.wallSize * 4, height: 2, angle: Math.PI / 4 }, this.world, Box2D );
    }

    contructInteractiveElements()
    {
        this.world.ball = new Ball({ debug: false, collisionId: 'ball', x: this.bakWidth - this.ballSize * 1.1, y: this.bakHeight / 1.9, radius: this.ballSize, density: 1, friction: 1, restitution: 0.4 }, this.world, Box2D );

        this.world.bumper1 = new Ball({ collisionId: 'bumper', x: this.bakWidth * 0.5, y: 5, radius: this.ballSize * 2, bodyType: Box2D.b2_kinematicBody }, this.world, Box2D );
        this.world.bumper2 = new Ball({ collisionId: 'bumper', x: this.bakWidth * 0.33, y: 2.5, radius: this.ballSize * 2, bodyType: Box2D.b2_kinematicBody }, this.world, Box2D );
        this.world.bumper3 = new Ball({ collisionId: 'bumper', x: this.bakWidth * 0.66, y: 2.5, radius: this.ballSize * 2, bodyType: Box2D.b2_kinematicBody }, this.world, Box2D );

        let verticesLowerBumperLeft = [];
        verticesLowerBumperLeft.push( new Box2D.b2Vec2( 0, 0 ) );
        verticesLowerBumperLeft.push( new Box2D.b2Vec2( 1, 2 ) );
        verticesLowerBumperLeft.push( new Box2D.b2Vec2( 0, 2 ) );

        this.world.lowerBumperLeft = new Poly({
            x: this.bakWidth * 0.25,
            y: this.bakHeight * 0.7,
            vertices: verticesLowerBumperLeft
        }, this.world, Box2D );
        this.world.lowerBumperLeftHitbox = new Box({ collisionId: 'lowerBumper', x: this.bakWidth * 0.3, y: this.bakHeight * 0.7, width: this.wallSize, height: 2, angle: -Math.PI / 6.2, type: Box2D.b2_kinematicBody }, this.world, Box2D );


        let verticesLowerBumperRight = [];
        verticesLowerBumperRight.push( new Box2D.b2Vec2( 0, 0 ) );
        verticesLowerBumperRight.push( new Box2D.b2Vec2( -1, 2 ) );
        verticesLowerBumperRight.push( new Box2D.b2Vec2( 0, 2 ) );

        this.world.lowerBumperRight = new Poly({
            x: this.bakWidth * 0.67,
            y: this.bakHeight * 0.7,
            vertices: verticesLowerBumperRight
        }, this.world, Box2D );
        this.world.lowerBumperRightHitbox = new Box({ collisionId: 'lowerBumper', x: this.bakWidth * 0.61, y: this.bakHeight * 0.7, width: this.wallSize, height: 2, angle: Math.PI / 6.2, type: Box2D.b2_kinematicBody }, this.world, Box2D );

        // flippers
        this.world.leftFlipper = new Flipper({ anchor: this.world.channelWallLeft2.body, x: this.bakWidth * 0.27, y: this.bakHeight * 0.9 }, this.world, Box2D );
        this.world.rightFlipper = new Flipper({ anchor: this.world.channelWallRight2.body, x: this.bakWidth * 0.65, y: this.bakHeight * 0.9, right: true }, this.world, Box2D );

        // plunger
        this.world.plunger = new Plunger({ x: this.bakWidth - 0.45, y: this.bakHeight }, this.world, Box2D );
    }

    constructSensors()
    {
        this.world.deathSensor = new Sensor({
            x: 0,
            y: this.bakHeight * 0.98,
            width: this.bakWidth * 0.9,
            height: 0.5,
            collisionId: 'death',
            onHit: () =>
            {
                this.world.ball.markForDeath( this.reset.bind( this ) );
            }
        }, this.world, Box2D );

        this.world.deathSensor2 = new Sensor({
            x: 31 - 19,
            y: this.bakHeight * 0.98,
            width: this.bakWidth * 0.88,
            height: 0.5,
            collisionId: 'death',
            onHit: () =>
            {
                this.world.ball2.markForDeath( this.reset.bind( this ) );
            }
        }, this.world, Box2D );


        this.world.scoreSensor = new Sensor({
            x: 20.4,
            y: 6,
            width: 0.2,
            height: 0.2,
            collisionId: 'scoreSensor',
            debug: true,
            onHit: () =>
            {
                this.emitScore( 50 );
            }
        }, this.world, Box2D );

        this.world.scoreSensor = new Sensor({
            x: 11.6,
            y: 6,
            width: 0.2,
            height: 0.2,
            collisionId: 'scoreSensor',
            onHit: () =>
            {
                this.emitScore( 50 );
            }
        }, this.world, Box2D );

        this.world.scoreSensor = new Sensor({
            x: 16,
            y: 2.4,
            width: 0.2,
            height: 0.2,
            collisionId: 'scoreSensor',
            onHit: () =>
            {
                this.emitScore( 15 );
            }
        }, this.world, Box2D );

        this.world.scoreSensor = new Sensor({
            x: 17.2,
            y: 2.7,
            width: 0.2,
            height: 0.2,
            collisionId: 'scoreSensor',
            onHit: () =>
            {
                this.emitScore( 15 );
            }
        }, this.world, Box2D );

        this.world.scoreSensor = new Sensor({
            x: 18.2,
            y: 3.2,
            width: 0.2,
            height: 0.2,
            collisionId: 'scoreSensor',
            onHit: () =>
            {
                this.emitScore( 15 );
            }
        }, this.world, Box2D );
    }

    reset()
    {
        if( this.world.ball.body.hasDied )
        {
            this.world.ball = new Ball({ debug: false, collisionId: 'ball', x: this.bakWidth - this.ballSize * 1.1, y: this.bakHeight / 1.9, radius: this.ballSize, density: 1, friction: 1, restitution: 0.4 }, this.world, Box2D );
            this.pixiRenderer.handleNewSprite( this.world.ball );
        }

        if( this.world.ball2.body.hasDied )
        {
            this.world.ball2 = new Ball({ debug: false, collisionId: 'ball', x: -20 + 33 + this.bakWidth - this.ballSize * 1.1, y: this.bakHeight / 1.9, radius: this.ballSize, density: 1, friction: 1, restitution: 0.4 }, this.world, Box2D );
            this.pixiRenderer.handleNewSprite( this.world.ball2 );

            this.emitter.emit( 'onDeath' );
        }
    }

    constructNewLevel()
    {
        let bakOffset = -20;
        this.world.bakPieces = [];

        let segs = 37; // segments in the circle
        for( let i = 0; i < segs; i++ )
        {
            if( ( i < 29 || i > 32 ) && ( i < 6 || i > 12 ) && ( i < 24 || i > 27 ) )
            {
                // ronde bak
                let r = 5;
                let bit = new Box({ x: bakOffset + 36 + Math.cos( Math.PI * 2 / segs * i ) * r, y: 5.8 + Math.sin( Math.PI * 2 / segs * i ) * r, angle: Math.PI * 2 / segs * i, width: 0.2, height: ( r * Math.PI * 2 ) / segs }, this.world, Box2D );
            }

            if( i > segs / 4 * 3 )
            {
                // ballguide buitenbocht
                let r = 4;
                let bit = new Box({ x: bakOffset + 39 + Math.cos( Math.PI * 2 / segs * i ) * r, y: 5 + Math.sin( Math.PI * 2 / segs * i ) * r, angle: Math.PI * 2 / segs * i, width: 0.2, height: ( r * Math.PI * 2 ) / segs }, this.world, Box2D );

                let r2 = 3;
                let bit2 = new Box({ x: bakOffset + 39 + Math.cos( Math.PI * 2 / segs * i ) * r2, y: 5.2 + Math.sin( Math.PI * 2 / segs * i ) * r2, angle: Math.PI * 2 / segs * i, width: 0.2, height: ( r2 * Math.PI * 2 ) / segs }, this.world, Box2D );
            }

            if( ( i > 12 && i < 24 ) || ( i >= 0 && i < 7 ) || ( i > 32 ) )
            {
                // muren in bol
                let r = 3.6;
                let bit = new Box({ x: bakOffset + 35.8 + Math.cos( Math.PI * 2 / segs * i ) * r, y: 5.8 + Math.sin( Math.PI * 2 / segs * i ) * r, angle: Math.PI * 2 / segs * i, width: 0.5, height: ( r * Math.PI * 2 ) / segs }, this.world, Box2D );
            }

            if( ( i === 27 || i === 29 || i === 31 ) )
            {
                let r = 3.6;
                let bit = new Box({ x: bakOffset + 35.8 + Math.cos( Math.PI * 2 / segs * i ) * r, y: 5.8 + Math.sin( Math.PI * 2 / segs * i ) * r, angle: Math.PI * 2 / segs * i, width: 0.5, height: ( r * Math.PI * 2 ) / segs / 2 }, this.world, Box2D );
            }

            if( i > 11 || i < 1 )
            {
                let r = 1.6;
                let bit = new Box({ x: bakOffset + 33.2 + Math.cos( Math.PI * 2 / segs * i ) * r, y: 1 + Math.sin( Math.PI * 2 / segs * i ) * r, angle: Math.PI * 2 / segs * i, width: 0.2, height: ( r * Math.PI * 2 ) / segs }, this.world, Box2D );
            }
        }

        this.world.ballGuide2 = new Box({ x: bakOffset + 43, y: 5, width: 0.2, height: this.bakHeight * 0.75 }, this.world, Box2D );
        this.world.ballGuide3 = new Box({ x: bakOffset + 42, y: 5, width: 0.2, height: this.bakHeight * 0.75 }, this.world, Box2D );
        this.world.ballGuide4 = new Box({ x: bakOffset + 31.8, y: 13.5, width: 0.2, height: this.bakHeight * 0.33 }, this.world, Box2D );
        this.world.ballGuide4b = new Box({ x: bakOffset + 40.6, y: 13.75, width: 0.2, height: this.bakHeight * 0.32 }, this.world, Box2D );
        this.world.ballGuide5 = new Box({ x: bakOffset + 32, y: this.bakHeight, width: this.bakWidth * 0.88, height: 0.25 }, this.world, Box2D );
        this.world.ballGuide6 = new Box({ x: bakOffset + 35, y: 1.2, width: 4, height: 0.2 }, this.world, Box2D );
        this.world.ballGuide7 = new Box({ x: bakOffset + 33.2, y: 10.5, width: 0.25, height: 2 }, this.world, Box2D );
        this.world.ballGuide8 = new Box({ x: bakOffset + 39, y: 10.2, width: 0.25, height: 2.3 }, this.world, Box2D );

        this.world.ballGuide9 = new Box({ x: bakOffset + 32.5, y: 12, width: 0.25, height: 2, angle: Math.PI / 4 }, this.world, Box2D );
        this.world.ballGuide10 = new Box({ x: bakOffset + 39.8, y: 12, width: 0.25, height: 2.3, angle: -Math.PI / 4 }, this.world, Box2D );

        this.world.plunger2 = new Plunger({ x: bakOffset + 33 + this.bakWidth - 0.45, y: this.bakHeight }, this.world, Box2D );

        this.world.ball2 = new Ball({ debug: false, collisionId: 'ball', x: bakOffset + 33 + this.bakWidth - this.ballSize * 1.1, y: this.bakHeight / 1.9, radius: this.ballSize, density: 1, friction: 1, restitution: 0.4 }, this.world, Box2D );

        this.world.bumper4 = new Ball({ collisionId: 'bumper', x: bakOffset + 31 + this.bakWidth * 0.5, y: 4.5, radius: this.ballSize * 2, bodyType: Box2D.b2_kinematicBody }, this.world, Box2D );
        this.world.bumper5 = new Ball({ collisionId: 'bumper', x: bakOffset + 31 + this.bakWidth * 0.43, y: 6.5, radius: this.ballSize * 2, bodyType: Box2D.b2_kinematicBody }, this.world, Box2D );
        this.world.bumper6 = new Ball({ collisionId: 'bumper', x: bakOffset + 31 + this.bakWidth * 0.66, y: 6, radius: this.ballSize * 2, bodyType: Box2D.b2_kinematicBody }, this.world, Box2D );
        this.world.bumper7 = new Ball({ collisionId: 'bumper', x: bakOffset + 26.7 + this.bakWidth * 0.66, y: 1, radius: this.ballSize * 2, bodyType: Box2D.b2_kinematicBody }, this.world, Box2D );

        this.world.channelWallLeft3 = new Box({ x: bakOffset + 31.7 + this.bakWidth * 0.13, y: this.bakHeight * 0.7, width: this.wallSize * 4, height: 2.3 }, this.world, Box2D );
        this.world.channelWallLeft4 = new Box({ x: bakOffset + 31.7 + this.bakWidth * 0.19, y: this.bakHeight * 0.8, width: this.wallSize * 4, height: 1.7, angle: -Math.PI / 3 }, this.world, Box2D );

        this.world.channelWallRight3 = new Box({ x: bakOffset + 31.7 + this.bakWidth * 0.75, y: this.bakHeight * 0.7, width: this.wallSize * 4, height: 2.3 }, this.world, Box2D );
        this.world.channelWallRight4 = new Box({ x: bakOffset + 31.7 + this.bakWidth * 0.69, y: this.bakHeight * 0.8, width: this.wallSize * 4, height: 1.7, angle: Math.PI / 3 }, this.world, Box2D );

        let verticesLowerBumperLeft = [];
        verticesLowerBumperLeft.push( new Box2D.b2Vec2( 0, 0 ) );
        verticesLowerBumperLeft.push( new Box2D.b2Vec2( 0.5, 2 ) );
        verticesLowerBumperLeft.push( new Box2D.b2Vec2( 0, 2 ) );

        this.world.lowerBumperLeft2 = new Poly({
            x: bakOffset + 31.7 + this.bakWidth * 0.25,
            y: this.bakHeight * 0.7,
            vertices: verticesLowerBumperLeft
        }, this.world, Box2D );
        this.world.lowerBumperLeftHitbox2 = new Box({ collisionId: 'lowerBumper', x: bakOffset + 31.5 + this.bakWidth * 0.3, y: this.bakHeight * 0.71, width: this.wallSize, height: 1.8, angle: -Math.PI / 12, type: Box2D.b2_kinematicBody }, this.world, Box2D );


        let verticesLowerBumperRight = [];
        verticesLowerBumperRight.push( new Box2D.b2Vec2( 0, 0 ) );
        verticesLowerBumperRight.push( new Box2D.b2Vec2( -0.5, 2 ) );
        verticesLowerBumperRight.push( new Box2D.b2Vec2( 0, 2 ) );

        this.world.lowerBumperRight2 = new Poly({
            x: bakOffset + 31.7 + this.bakWidth * 0.67,
            y: this.bakHeight * 0.7,
            vertices: verticesLowerBumperRight
        }, this.world, Box2D );
        this.world.lowerBumperRightHitbox2 = new Box({ collisionId: 'lowerBumper', x: bakOffset + 31.9 + this.bakWidth * 0.61, y: this.bakHeight * 0.71, width: this.wallSize, height: 1.8, angle: Math.PI / 12, type: Box2D.b2_kinematicBody }, this.world, Box2D );

        // flippers
        this.world.leftFlipper2 = new Flipper({ anchor: this.world.channelWallLeft4.body, x: bakOffset + 31.7 + this.bakWidth * 0.27, y: this.bakHeight * 0.9 }, this.world, Box2D );
        this.world.rightFlipper2 = new Flipper({ anchor: this.world.channelWallRight4.body, x: bakOffset + 31.7 + this.bakWidth * 0.65, y: this.bakHeight * 0.9, right: true }, this.world, Box2D );
    }

    emitScore( pAmount )
    {
        console.log( 'scored', pAmount );
        this.emitter.emit( 'onScore', pAmount );
    }
};
