import Config from '@/app/game/data/config';

import World from '@/app/game/World';
import Engine from '@/app/game/Engine';

import DebugRenderer from '@/app/game/renderers/DebugRenderer';
import PixiRenderer from '@/app/game/renderers/PIXIRenderer';

import ContactHandler from '@/app/game/handlers/ContactHandler';
import InputHandler from '@/app/game/handlers/InputHandler';

export default class Pinball {
    constructor( settings )
    {
        // super();

        this.settings = settings;

        if( typeof Box2D === 'object' )
        {
            this.init( Box2D );
        }
        else
        {
            if( settings.browserCheck.isIE || settings.browserCheck.isSafari )
            {
                Box2D().then( this.init.bind( this ) );
            }
            else
            {
                Box2D({ wasmJSMethod: 'native-wasm', wasmBinaryFile: '/static/scripts/Box2D_v2.3.1_min.wasm.wasm' }).then( this.init.bind( this ) );
            }
        }
    }

    init( Box2D )
    {
        window.Box2D = Box2D;

        let gravity = new Box2D.b2Vec2( 0, Config.GRAVITY_FORCE );

        this.world = new World( gravity, true );
        this.world.emitter = this.settings.emitter;

        this.contactHandler = new ContactHandler( this.world );
        this.inputHandler = new InputHandler( this.world );

        this.debugRenderer = new DebugRenderer({ world: this.world, canvas: this.settings.debugCanvas });
        this.pixiRenderer = new PixiRenderer({ world: this.world, canvas: this.settings.pixiCanvas });

        this.world.pixiRenderer = this.pixiRenderer;

        this.engine = new Engine({ world: this.world, renderer: this.debugRenderer, pixiRenderer: this.pixiRenderer });
        this.engine.start();
    }

    destroy()
    {
    }

    handleInput( pEvent )
    {
        this.inputHandler.sortInput( pEvent );
    }
}
