// import EventEmitter from 'eventemitter3';

export default class ContactHandler
{
    constructor( pWorld )
    {
        // super();
        this.pWorld = pWorld;
        this.world = pWorld.world;

        this.contactListener = new Box2D.JSContactListener();
        this.contactListener.BeginContact = this.BeginContact.bind( this );
        this.contactListener.PreSolve = this.PreSolve.bind( this );
        this.contactListener.PostSolve = this.PostSolve.bind( this );
        this.contactListener.EndContact = this.EndContact.bind( this );

        this.world.SetContactListener( this.contactListener );

        this.stopEmitting = false;
    }

    BeginContact( contactPtr )
    {
        let contact = Box2D.wrapPointer( contactPtr, Box2D.b2Contact );

        let fixtureA = contact.GetFixtureA();
        let fixtureB = contact.GetFixtureB();

        let bodyA = fixtureA.GetBody();
        let bodyB = fixtureB.GetBody();

        if( fixtureA.IsSensor() && bodyB.collisionId === 'ball' )
        {
            bodyA.onHit();
        }

        if( fixtureB.IsSensor() && bodyA.collisionId === 'ball' )
        {
            bodyB.onHit();
        }
    }

    PreSolve()
    {

    }

    PostSolve()
    {

    }

    EndContact( contactPtr )
    {
        let contact = Box2D.wrapPointer( contactPtr, Box2D.b2Contact );

        let fixtureA = contact.GetFixtureA();
        let fixtureB = contact.GetFixtureB();

        let bodyA = fixtureA.GetBody();
        let bodyB = fixtureB.GetBody();

        if( bodyA.collisionId === 'ball' && bodyB.collisionId === 'bumper' ||
            bodyA.collisionId === 'bumper' && bodyB.collisionId === 'ball' )
        {
            let magnitude = 200;
            let angle = Math.atan2( bodyB.GetPosition().get_x() - bodyA.GetPosition().get_x(), bodyA.GetPosition().get_y() - bodyB.GetPosition().get_y() );
            let force = new Box2D.b2Vec2( Math.cos( angle ) * magnitude, Math.sin( angle ) * magnitude );

            if( bodyA.collisionId === 'ball' )
            {
                bodyA.ApplyForce( force, this.world.ball.body.GetWorldCenter() ); // shoot ball away
                bodyB.onHit(); // anim bumper

                if( bodyB.score )
                {
                    this.pWorld.emitScore( bodyB.score );
                }
            }
            else if( bodyB.collisionId === 'ball' )
            {
                bodyB.ApplyForce( force, this.world.ball.body.GetWorldCenter() ); // shoot ball away
                bodyA.onHit(); // anim bumper

                if( bodyA.score )
                {
                    this.pWorld.emitScore( bodyA.score );
                }
            }
        }

        if( bodyA.collisionId === 'ball' && bodyB.collisionId === 'lowerBumper' ||
            bodyA.collisionId === 'lowerBumper' && bodyB.collisionId === 'ball' )
        {
            let ball = bodyA;
            if( bodyB.collisionId === 'ball' )
            {
                ball = bodyB;
            }

            let force = new Box2D.b2Vec2( 0, -500 );

            ball.ApplyForce( force, this.world.ball.body.GetWorldCenter() );

            if( bodyA.collisionId === 'lowerBumper' )
            {
                this.pWorld.emitScore( bodyA.score );
            }
            else
            {
                this.pWorld.emitScore( bodyB.score );
            }
        }
    }
}
