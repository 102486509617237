export default class Plunger
{
    constructor( settings, pWorld, Box2D )
    {
        this.world = pWorld;

        let anchor = new Box2D.b2PolygonShape();
        anchor.SetAsBox( 0.5, 0.1, new Box2D.b2Vec2( 0, 0 ), 0 );

        let pin = new Box2D.b2PolygonShape();
        pin.SetAsBox( 0.3, 1, new Box2D.b2Vec2( 0, -1 ), 0 );

        this.fixtureDef = new Box2D.b2FixtureDef();
        this.fixtureDef.set_density( 0 );
        this.fixtureDef.set_shape( anchor );
        this.fixtureDef.get_filter().set_maskBits( 0x0000 );

        this.pinFixtureDef = new Box2D.b2FixtureDef();

        this.pinFixtureDef.set_density( 10 );
        this.pinFixtureDef.set_shape( pin );

        this.bodyDef = new Box2D.b2BodyDef();
        this.bodyDef.set_position( new Box2D.b2Vec2( settings.x, settings.y ) );

        this.pinBodyDef = new Box2D.b2BodyDef();
        this.pinBodyDef.set_position( new Box2D.b2Vec2( settings.x, settings.y ) );
        this.pinBodyDef.set_type( Box2D.b2_dynamicBody );

        this.body = pWorld.CreateBody( this.bodyDef );
        this.body.CreateFixture( this.fixtureDef );

        this.pinBody = pWorld.CreateBody( this.pinBodyDef );
        this.pinBody.CreateFixture( this.pinFixtureDef );

        let damping = 1;
        let freq = 1;

        this.prismaticJointDef = new Box2D.b2PrismaticJointDef();
        this.prismaticJointDef.set_bodyA( this.body );
        this.prismaticJointDef.set_bodyB( this.pinBody );
        this.prismaticJointDef.set_collideConnected( false );

        this.prismaticJointDef.set_localAxisA( new Box2D.b2Vec2( 0, -1 ) );

        this.prismaticJointDef.set_enableLimit( true );
        this.prismaticJointDef.set_lowerTranslation( -2 );
        this.prismaticJointDef.set_upperTranslation( 0 );

        this.prismaticJointDef.set_enableMotor( true );
        this.prismaticJointDef.set_maxMotorForce( 5000 );
        this.prismaticJointDef.set_motorSpeed( 5 );

        this.prismaticJoint = Box2D.castObject( this.world.CreateJoint( this.prismaticJointDef ), Box2D.b2PrismaticJoint );

        this.pinBody.SetFixedRotation( Math.PI );
        this.body.SetUserData( this );
    }

    engage()
    {
        this.prismaticJoint.SetMotorSpeed( -5 );
    }

    disengage()
    {
        this.prismaticJoint.SetMotorSpeed( 50 );
    }
}
