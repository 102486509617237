/* eslint-disable */

export default class Flipper
{
    constructor( settings, pWorld, Box2D )
    {
        this.settings = settings;
        let flipperLength = 1.62;
        let tipWidth = 0.16;
        let flipperWidth = 0.4;

        let flipperVertices = [
            new Box2D.b2Vec2( 0, 0 ),
            new Box2D.b2Vec2( flipperLength, 0 ),
            new Box2D.b2Vec2( flipperLength, tipWidth ),
            new Box2D.b2Vec2( 0, flipperWidth )
        ];


        if( settings.right )
        {
            flipperVertices = [
                new Box2D.b2Vec2( 0, 0 ),
                new Box2D.b2Vec2( -flipperLength, 0 ),
                new Box2D.b2Vec2( -flipperLength, tipWidth ),
                new Box2D.b2Vec2( 0, flipperWidth )
            ];
        }

        this.world = pWorld;
        this.shapeDef = new Box2D.b2PolygonShape();

        let buffer = Box2D._malloc( flipperVertices.length * 8 );
        let offset = 0;

        for( let i = 0; i < flipperVertices.length; i++ )
        {
            Box2D.HEAPF32[ buffer + offset >> 2 ] = flipperVertices[i].get_x();
            Box2D.HEAPF32[ buffer + (offset + 4) >> 2 ] = flipperVertices[i].get_y();
            offset += 8;
        }

        let ptr_wrapped = Box2D.wrapPointer( buffer, Box2D.b2Vec2 );
        this.shapeDef.Set( ptr_wrapped, flipperVertices.length );

        this.fixtureDef = new Box2D.b2FixtureDef();
        this.fixtureDef.set_density( settings.density ? settings.density : 100 );
        this.fixtureDef.set_shape( this.shapeDef );
        this.fixtureDef.set_friction( settings.friction ? settings.friction : 1 );

        if( settings.debug )
        {
            console.log( 'fixtureDef', this.fixtureDef.get_filter() );
        }

        this.bodyDef = new Box2D.b2BodyDef();

        this.bodyDef.set_position( new Box2D.b2Vec2( settings.x, settings.y ) );
        this.bodyDef.set_type( Box2D.b2_dynamicBody );
        this.bodyDef.set_angle( settings.right ? -0.2 : 0.2 );

        this.body = pWorld.CreateBody( this.bodyDef );
        this.body.CreateFixture( this.fixtureDef );
        this.body.name = settings.name ? settings.name : '';
        this.body.collisionId = settings.collisionId ? settings.collisionId : '';
        // this.body.SetGravityScale( 0 );

        this.body.SetUserData( this );

        this.revoluteJointDef = new Box2D.b2RevoluteJointDef();
        this.revoluteJointDef.set_bodyA( settings.anchor );
        this.revoluteJointDef.set_bodyB( this.body );
        this.revoluteJointDef.set_collideConnected( false );

        this.revoluteJointDef.set_localAnchorA( new Box2D.b2Vec2( 0, 1 ) );
        this.revoluteJointDef.set_localAnchorB( new Box2D.b2Vec2( this.body.GetLocalCenter().get_x() - 0.5, this.body.GetLocalCenter().get_y() + 0.05 ) );
        if( settings.right )
        {
            this.revoluteJointDef.set_localAnchorA( new Box2D.b2Vec2( 0, 1 ) );
            this.revoluteJointDef.set_localAnchorB( new Box2D.b2Vec2( this.body.GetLocalCenter().get_x() + 0.5, this.body.GetLocalCenter().get_y() + 0.05 ) );
        }

        this.revoluteJointDef.set_referenceAngle( Math.PI * 0.5 );
        if( settings.right )
        {
            this.revoluteJointDef.set_referenceAngle( -Math.PI * 0.5 );
        }

        this.revoluteJointDef.set_enableMotor( true );
        this.revoluteJointDef.set_maxMotorTorque( 10000 );
        this.revoluteJointDef.set_motorSpeed( 2000 );

        this.revoluteJointDef.set_enableLimit( true );

        this.revoluteJointDef.set_lowerAngle( -Math.PI * 0.4 );

        if( settings.right )
        {
            this.revoluteJointDef.set_motorSpeed( -2000 );

            this.revoluteJointDef.set_upperAngle( Math.PI * 0.4 );
            this.revoluteJointDef.set_lowerAngle( -Math.PI * 0 );
        }

        this.revoJoint = Box2D.castObject( this.world.CreateJoint( this.revoluteJointDef ), Box2D.b2RevoluteJoint );
    }

    engage()
    {
        // this.revoJoint.EnableMotor( true );
        if( this.settings.right )
        {
            this.revoJoint.SetMotorSpeed( 4000 );
        }
        else
        {
            this.revoJoint.SetMotorSpeed( -4000 );
        }
    }

    disengage()
    {
        if( this.settings.right )
        {
            this.revoJoint.SetMotorSpeed( -2000 );
        }
        else
        {
            this.revoJoint.SetMotorSpeed( 2000 );
        }
        // this.revoJoint.EnableMotor( false );
    }
};
