// import { PIXI } from '@/vendor';

// Get class references
// const { Application, Sprite, Container, lights, display } = PIXI;
// const { diffuseGroup, normalGroup, lightGroup } = PIXI.lights;
// const { Layer, Stage } = display;

const PTM = 32;

export default class PixiRenderer
{
    constructor( pSettings )
    {
        this.settings = pSettings;

        this.sizes = this.settings.canvas.getBoundingClientRect();

        this.app = new PIXI.Application({
            view: this.settings.canvas,
            backgroundColor: 0x000000,
            width: this.sizes.width,
            height: this.sizes.height
        });

        this.app.stage = new PIXI.Container();
        this.scaleUpContainer = new PIXI.Container();
        this.scaleUpContainer.position.x = 10;
        this.scaleUpContainer.position.y = 10;

        this.scaleUpContainer.scale.x = PTM;
        this.scaleUpContainer.scale.y = PTM;

        this.app.stage.addChild( this.scaleUpContainer );

        this.worldOffset = 7.8;

        this.worldScene = new PIXI.Container();
        this.worldScene.position.x = -this.worldOffset;
        this.scaleUpContainer.addChild( this.worldScene );

        this.renderStaticWorld();

        this.interactiveElems = [];

        for( let item in pSettings.world.world )
        {
            if( typeof pSettings.world.world[ item ] === 'object' )
            {
                if( pSettings.world.world[item].sprite )
                {
                    // initial adding of elements
                    this.worldScene.addChild( pSettings.world.world[item].sprite );
                }
            }
        }
    }

    renderStaticWorld()
    {
        this.backgroundSprite = new PIXI.Sprite.fromImage( '/static/img/bak-back.png' );
        this.backgroundSprite.width = 8.13 * 2;
        this.backgroundSprite.height = 9.96 * 2;
        this.backgroundSprite.x = this.worldOffset;

        this.worldScene.addChild( this.backgroundSprite );
    }

    handleNewSprite( pElem )
    {
        this.worldScene.addChild( pElem.sprite );
    }

    update()
    {
        // console.log( this.worldScene.children.length );
    }

    createLightContainer()
    {
        let diffuse = PIXI.Sprite.fromImage( '/static/img/bol.png' );
        diffuse.parentGroup = PIXI.lights.diffuseGroup;

        let normals = PIXI.Sprite.fromImage( '/static/img/bol-normals.jpg' );
        normals.parentGroup = PIXI.lights.normalGroup;

        let light = new PIXI.lights.PointLight( 0xffffff, 1 );
        light.x = 200;
        light.y = 400;

        let background = new PIXI.Container();
        background.addChild( normals, diffuse, light );

        this.app.stage.addChild(
            new PIXI.display.Layer( PIXI.lights.diffuseGroup ),
            new PIXI.display.Layer( PIXI.lights.normalGroup ),
            new PIXI.display.Layer( PIXI.lights.lightGroup ),
            background
        );
    }
};
;
