/* eslint-disable */
var context;

/* HELPERS */
//to replace original C++ operator =
function copyVec2( vec )
{
    return new Box2D.b2Vec2(vec.get_x(), vec.get_y());
}

//to replace original C++ operator * (float)
function scaleVec2( vec, scale )
{
    vec.set_x( scale * vec.get_x() );
    vec.set_y( scale * vec.get_y() );
}

//to replace original C++ operator *= (float)
function scaledVec2( vec, scale )
{
    return new Box2D.b2Vec2(scale * vec.get_x(), scale * vec.get_y());
}


// http://stackoverflow.com/questions/12792486/emscripten-bindings-how-to-create-an-accessible-c-c-array-from-javascript
function createChainShape( vertices, closedLoop )
{
    var shape = new Box2D.b2ChainShape();
    var buffer = Box2D.allocate(vertices.length * 8, 'float', Box2D.ALLOC_STACK);
    var offset = 0;
    for (var i=0;i<vertices.length;i++) {
        Box2D.setValue(buffer+(offset), vertices[i].get_x(), 'float'); // x
        Box2D.setValue(buffer+(offset+4), vertices[i].get_y(), 'float'); // y
        offset += 8;
    }
    var ptr_wrapped = Box2D.wrapPointer(buffer, Box2D.b2Vec2);
    if ( closedLoop )
        shape.CreateLoop(ptr_wrapped, vertices.length);
    else
        shape.CreateChain(ptr_wrapped, vertices.length);
    return shape;
}

function createPolygonShape( vertices )
{
    var shape = new Box2D.b2PolygonShape();
    var buffer = Box2D.allocate(vertices.length * 8, 'float', Box2D.ALLOC_STACK);
    var offset = 0;
    for (var i=0;i<vertices.length;i++) {
        Box2D.setValue(buffer+(offset), vertices[i].get_x(), 'float'); // x
        Box2D.setValue(buffer+(offset+4), vertices[i].get_y(), 'float'); // y
        offset += 8;
    }
    var ptr_wrapped = Box2D.wrapPointer(buffer, Box2D.b2Vec2);
    shape.Set(ptr_wrapped, vertices.length);
    return shape;
}

function createRandomPolygonShape( radius )
{
    var numVerts = 3.5 + Math.random() * 5;
    numVerts = numVerts | 0;
    var verts = [];
    for (var i = 0; i < numVerts; i++) {
        var angle = i / numVerts * 360.0 * 0.0174532925199432957;
        verts.push( new b2Vec2( radius * Math.sin(angle), radius * -Math.cos(angle) ) );
    }
    return createPolygonShape(verts);
}


/* DRAW FUNCTIONS */

function drawAxes( ctx )
{
    ctx.strokeStyle = 'rgb(192,0,0)';
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(1, 0);
    ctx.stroke();

    ctx.strokeStyle = 'rgb(0,192,0)';
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(0, 1);
    ctx.stroke();
}

function setColorFromDebugDrawCallback( color, context )
{
    var col = Box2D.wrapPointer( color, Box2D.b2Color );
    var red = (col.get_r() * 255)|0;
    var green = (col.get_g() * 255)|0;
    var blue = (col.get_b() * 255)|0;
    var colStr = red+","+green+","+blue;

    context.fillStyle = "rgba("+colStr+",0.5)";
    context.strokeStyle = "rgb("+colStr+")";
}

function drawSegment( vert1, vert2, context )
{
    var vert1V = Box2D.wrapPointer( vert1, Box2D.b2Vec2 );
    var vert2V = Box2D.wrapPointer( vert2, Box2D.b2Vec2 );

    context.beginPath();
    context.moveTo( vert1V.get_x(), vert1V.get_y() );
    context.lineTo( vert2V.get_x(), vert2V.get_y() );
    context.stroke();
}

function drawPolygon( vertices, vertexCount, fill, context )
{
    context.beginPath();

    for( var tmpI=0; tmpI<vertexCount; tmpI++ )
    {
        var vert = Box2D.wrapPointer(vertices+(tmpI*8), Box2D.b2Vec2);

        if ( tmpI == 0 )
            context.moveTo(vert.get_x(),vert.get_y());
        else
            context.lineTo(vert.get_x(),vert.get_y());
    }

    context.closePath();

    if (fill)
        context.fill();

    context.stroke();
}

function drawCircle( center, radius, axis, fill, context )
{
    var centerV = Box2D.wrapPointer( center, Box2D.b2Vec2 );
    var axisV = Box2D.wrapPointer( axis, Box2D.b2Vec2 );

    context.beginPath();
    context.arc(centerV.get_x(),centerV.get_y(), radius, 0, 2 * Math.PI, false);

    if (fill)
        context.fill();
    context.stroke();

    if (fill) {
        //render axis marker
        var scaledAxisVec = scaledVec2(axisV, radius);
        var vert2V = copyVec2(centerV);
        vert2V.op_add( scaledAxisVec );
        context.beginPath();
        context.moveTo(centerV.get_x(),centerV.get_y());
        context.lineTo(vert2V.get_x(),vert2V.get_y());
        context.stroke();

        vert2V.__destroy__();
        vert2V = null;

        scaledAxisVec.__destroy__();
        scaledAxisVec.null;
    }
}

function drawTransform( transform, ctx )
{
    var trans = Box2D.wrapPointer( transform, Box2D.b2Transform );
    var pos = trans.get_p();
    var rot = trans.get_q();

    ctx.save();
    ctx.translate(pos.get_x(), pos.get_y());
    ctx.scale(0.5,0.5);
    ctx.rotate(rot.GetAngle());
    ctx.lineWidth *= 2;

    drawAxes(context);

    context.restore();
}

export default function( ctx, Box2D )
{
    context = ctx;
    let once = 1;

    var debugDraw = new Box2D.JSDraw();

    debugDraw.DrawSegment = function( vert1, vert2, color)
    {
        setColorFromDebugDrawCallback( color, ctx );
        drawSegment( vert1, vert2, ctx );
    };

    debugDraw.DrawPolygon = function( vertices, vertexCount, color )
    {
        setColorFromDebugDrawCallback( color, ctx );
        drawPolygon( vertices, vertexCount, false, ctx );
    };

    debugDraw.DrawSolidPolygon = function( vertices, vertexCount, color)
    {
        setColorFromDebugDrawCallback( color, ctx );
        drawPolygon( vertices, vertexCount, true, ctx );
    };

    debugDraw.DrawCircle = function( center, radius, color )
    {
        setColorFromDebugDrawCallback( color, ctx );
        var dummyAxis = b2Vec2(0,0);
        drawCircle( center, radius, dummyAxis, false, ctx );
    };

    debugDraw.DrawSolidCircle = function( center, radius, axis, color )
    {
        setColorFromDebugDrawCallback( color, ctx );
        drawCircle( center, radius, axis, true, ctx );
    };

    debugDraw.DrawTransform = function( transform )
    {
        drawTransform( transform, ctx );
    };

    return debugDraw;
};
