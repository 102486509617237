/* eslint-disable */
import { TimelineMax, TweenMax } from '@/vendor';

export default class Ball
{
    constructor( settings, pWorld, Box2D )
    {
        this.settings = settings;
        this.world = pWorld;
        this.shapeDef = new Box2D.b2CircleShape();
        this.shapeDef.set_m_radius( settings.radius );

        if( settings.debug )
        {
            console.log( 'shapeDef', this.shapeDef );
        }

        this.fixtureDef = new Box2D.b2FixtureDef();
        this.fixtureDef.set_density( settings.density ? settings.density : 0 );
        this.fixtureDef.set_shape( this.shapeDef );
        this.fixtureDef.set_friction( settings.friction ? settings.friction : 0 );
        this.fixtureDef.set_restitution( settings.restitution ? settings.restitution : 0 );

        this.fixtureDef.get_filter().set_categoryBits( 0x0002 );
        this.fixtureDef.get_filter().set_maskBits( 0x0007 );

        if( settings.debug )
        {
            console.log( 'fixtureDef', this.fixtureDef );
        }

        this.bodyDef = new Box2D.b2BodyDef();

        this.bodyDef.set_position( new Box2D.b2Vec2( settings.x, settings.y ) );
        this.bodyDef.set_angle( Math.random() * Math.PI * 2 );
        this.bodyDef.set_type( settings.bodyType ? settings.bodyType : Box2D.b2_dynamicBody );

        if( settings.debug )
        {
            console.log( 'bodyDef', this.bodyDef, settings.x, settings.y );
        }


        this.body = pWorld.CreateBody( this.bodyDef );
        this.body.CreateFixture( this.fixtureDef );
        this.body.name = 'Ball'
        this.body.ballType = settings.ballType ? settings.ballType : 'lotto';
        this.body.collisionId = settings.collisionId ? settings.collisionId : '';
        this.body.shouldDie = false;
        this.body.hasDied = false;

        if( this.body.collisionId === 'bumper' )
        {
            this.body.score = 20;
        }

        this.body.SetUserData( this );

        this.body.onHit = function()
        {
            if( this.body.collisionId === 'bumper' )
            {
                let tl = new TimelineMax();
                tl.to( this.sprite, 0.05, { width: '+=0.2', height: '+=0.2', yoyo: true, repeat: 1 });
            }
        }.bind( this );


        this.sprite = PIXI.Sprite.fromImage( '/static/img/bol.png' );
        this.sprite.anchor.set( 0.5 );
        // this.sprite.scale.x = this.sprite.scale.y = 1;
        this.sprite.x = settings.x;
        this.sprite.y = settings.y;
        this.sprite.width =
        this.sprite.height = settings.radius * 2;
        this.sprite.rotation = this.body.GetAngle();

        this.sprite.scale.y = -this.sprite.scale.y;
    }

    markForDeath( pCallBack )
    {
        this.body.shouldDie = true;
        this.callback = pCallBack;
    }

    update()
    {
        if( this.body.hasDied === true )
        {
            return;
        }

        // air resistance

        // let k = 1e-4 * this.settings.density * (( this.settings.radius * 2 ) * 2 );
        // let velX = this.body.GetLinearVelocity().get_x();
        // let velY = this.body.GetLinearVelocity().get_y();

        // var dragForce = new Box2D.b2Vec2( -k * this.body.GetLinearVelocity().Length() * velX, -k * this.body.GetLinearVelocity().Length() * velY );
        // this.body.ApplyForce( dragForce, this.body.GetWorldCenter() );


        // sprite positioning

        let pos = this.body.GetPosition();
        this.sprite.x = pos.get_x();
        this.sprite.y = pos.get_y();
        this.sprite.rotation = this.body.GetAngle();

        if( this.body.shouldDie === true )
        {
            this.destroy();
            return;
        }
    }

    destroy()
    {
        this.world.DestroyBody( this.body );
        this.sprite.destroy();
        this.body.hasDied = true;

        if( this.callback )
        {
            this.callback();
        }
        this.callback = null;
    }
}
