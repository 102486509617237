/* eslint-disable */

import { createDebugDraw } from '@/vendor';
import Config from '@/app/game/data/config';

const e_shapeBit = 0x0001;
const e_centerOfMassBit = 0x0002;
const e_jointBit = 0x0004;
const e_pairBit = 0x0008;

export default class DebugRenderer
{
    constructor( pSettings )
    {
        this.settings = pSettings;
        this.world = this.settings.world.world;

        this.canvas = this.settings.canvas;

        this.rect = this.canvas.getBoundingClientRect();
        this.ctx = this.canvas.getContext( '2d' );

        this.debugDraw = createDebugDraw( this.ctx, Box2D );
        this.debugDraw.SetFlags( e_shapeBit | e_centerOfMassBit | e_jointBit | e_pairBit );

        this.world.SetDebugDraw( this.debugDraw );

        this.draw();
    }

    draw()
    {
        this.ctx.clearRect( 0, 0, window.innerWidth, window.innerHeight );

        this.ctx.save();
        this.ctx.translate( 10, 10 );
        this.ctx.scale( 1, 1 );
        this.ctx.scale( Config.PTM, Config.PTM );
        this.ctx.lineWidth /= Config.PTM;

        this.world.DrawDebugData();

        this.ctx.restore();

        this.rafRef = requestAnimationFrame( this.draw.bind( this ) );
    }

    stop()
    {
        cancelAnimationFrame( this.rafRef );
    }
}
